<template>
  <v-container fluid>
    <div class="d-flex justify-space-between mb-5">
      <div class="d-flex align-baseline">
        <h1 class="text-h1">風險評估</h1>
        <v-btn text @click="isExpand = !isExpand" class="ml-2"
          ><v-icon>mdi-filter-variant</v-icon><span class="ml-1">篩選</span>
        </v-btn>
      </div>
      <div class="d-flex">
        <v-btn class="mr-3" color="primary" @click="exportDialog = true"
          >匯出</v-btn
        >
        <v-btn
          v-if="rolesCheck('riskEvaluationAdd')"
          color="primary"
          @click="addDialog = true"
          >新增版本</v-btn
        >
      </div>
    </div>

    <material-filter
      @filter="filterChoose"
      :isExpand="isExpand"
      :showUnit="true"
    ></material-filter>

    <material-table v-model="select_case" :headers="header" :items="item_list">
      <template #status="{ item }">
        <chip-status small :status="item.status"> </chip-status>
      </template>

      <template #no="{ item }">
        {{ versionText(item.no) }}
      </template>
      <template #fieldVer="{ item }">
        <v-btn
          text
          class="pa-0"
          color="primary"
          to="/audit-field/unit-field/1"
          >{{ unitTrans[item.unitFieldId] || "-" }}</v-btn
        >
      </template>

      <template #allocationVer="{ item }">
        <v-btn
          text
          class="pa-0"
          color="primary"
          :to="'/allocation/setting/' + item.allocationId"
        >
          {{ allocationTrans[item.allocationId] || "-" }}
        </v-btn>
      </template>
      <template #updatedAt="{ item }">
        {{ dateFormate(item.updatedAt) }}
      </template>
      <template #versioningAt="{ item }">
        {{ dateFormate(item.versioningAt) }}
      </template>
      <template #createdAt="{ item }">
        {{ dateFormate(item.createdAt) }}
      </template>
      <template #actions="{ item }">
        <div class="text-no-wrap">
          <v-icon @click="toPage(item)" class="mr-4">mdi-eye</v-icon>
          <v-icon
            v-if="rolesCheck('riskEvaluationDelete')"
            @click="openDeleteDialog(item)"
            :disabled="item.status != 0 && item.status != 1"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </material-table>
    <material-pagination
      :totalPage="pageMeta.totalPage"
      @pageFilter="pageFilter"
    >
    </material-pagination>

    <!-- === Dialogs === -->
    <!-- 新增風險評估版本 -->
    <material-dialog
      title="新增風險評估版本"
      v-model="addDialog"
      max-width="400"
    >
      <div class="mx-3">
        <v-select
          v-model="allocation"
          prependTitle="引用配分"
          class="mb-3"
          label="選擇配分版本"
          :items="allocationList"
          item-value="id"
          outlined
          hide-details
          single-line
          dense
        >
          <template #prepend>
            <div class="text-no-wrap mr-2">引用配分</div>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.year }}-{{ versionText(item.no) }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.year }}-{{ versionText(item.no) }}
          </template>
        </v-select>
        <v-select
          v-model="group"
          prependTitle="引用領域"
          label="選擇單位領域版本"
          :items="unitFieldList"
          class="mb-8"
          @change="selectUnit"
          outlined
          hide-details
          single-line
          dense
        >
          <template #prepend>
            <div class="text-no-wrap mr-2">引用領域</div>
          </template>
          <template v-slot:selection="{ item }">
            <!-- {{ item }} -->
            {{ item.year }}-{{ versionText(item.no) }}-{{ item.group }}
          </template>
          <template v-slot:item="{ item }">
            <!-- {{ item }} -->

            {{ item.year }}-{{ versionText(item.no) }}-{{ item.group }}
          </template>
        </v-select>
        <p>
          新增
          <span class="primary--text"> {{ selectedUnitField.length }}</span>
          個風險評估版本
        </p>
        <chip-grey v-for="(item, i) in selectedUnitField" :key="i" class="mr-1">
          {{ item.year }}-{{ versionText(item.no) }}-{{ item.group }}
        </chip-grey>
      </div>
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel
          @click="
            addDialog = false;
            resetUnit();
          "
          >取消</btn-cancel
        >
        <v-btn color="primary" depressed @click="addRiskEvaluation">
          新增
        </v-btn>
      </template>
    </material-dialog>
    <!-- 刪除風險評估版本 -->
    <material-dialog
      title="刪除風險評估版本"
      v-model="deleteAlert"
      max-width="400"
    >
      您確定要刪除風險評估版本
      <chip-grey>
        {{ `${nowItem.year}-${nowItem.no}` }}
      </chip-grey>
      嗎？
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deleteRiskEvaluation" depressed
          >刪除版本</v-btn
        >
        <btn-cancel @click="deleteAlert = false">保留版本</btn-cancel>
      </template>
    </material-dialog>
    <!-- 匯出風險評估版本 -->
    <material-dialog
      title="匯出風險評估版本"
      v-model="exportDialog"
      max-width="420"
    >
      <div class="mx-3">
        <material-select
          prependTitle="選擇年度"
          label="選擇年度"
        ></material-select>
        <material-select
          prependTitle="選擇版本"
          label="選擇版本"
        ></material-select>
        <material-select prependTitle="單位別" label="選擇單位">
          <template #append-outer>
            <v-btn outlined color="primary">選擇</v-btn>
          </template>
        </material-select>
        <p>已選擇 <span class="primary--text"> 1</span> 個風險評估版本</p>
        <chip-grey class="mr-1"> 2022-01 分公司</chip-grey>
      </div>
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="exportDialog = false">取消</btn-cancel>
        <v-btn color="primary" depressed @click="exportDialog = false">
          匯出
        </v-btn>
      </template>
    </material-dialog>
  </v-container>
</template>
<script>
import { sync } from "vuex-pathify";
import MaterialTable from "../../components/MaterialTable.vue";
import AllocationSetting from "./AllocationSetting.vue";
import AllocationAdd from "./components/AllocationAdd.vue";
import {
  apiGetRiskEvaluation,
  apiAddRiskEvaluation,
  apiDeleteRiskEvaluation,
} from "../../api/risk-evaluation";
import { apiGetUnitField } from "../../api/unit-field";
import { apiGetAllocationData } from "../../api/allocation";
import {
  searchMixin,
  versionMixin,
  dateFormateMixin,
  checkRolesMixin,
} from "../../helper";

export default {
  name: "AllocationManagement",
  components: {
    "allocation-setting": AllocationSetting,
    "allocation-add": AllocationAdd,
    MaterialTable,
  },
  mixins: [searchMixin, versionMixin, dateFormateMixin, checkRolesMixin],
  computed: {
    ...sync("global", ["alert"]),
  },
  data() {
    return {
      isExpand: false,
      header: [
        { text: "年度", value: "year", sortable: false },
        { text: "版本", value: "no", sortable: false },
        { text: "單位", value: "group", sortable: false, width: 100 },
        { text: "狀態", value: "status", sortable: false },
        { text: "單位領域版本", value: "fieldVer", sortable: false },
        { text: "配分版本", value: "allocationVer", sortable: false },
        { text: "編輯人", value: "editor", sortable: false },
        { text: "更新時間", value: "updatedAt" },
        { text: "定版時間", value: "versioningAt" },
        { text: "建立時間", value: "createdAt" },
        { text: "操作", value: "actions", sortable: false },
      ],
      item_list: [
        // {
        //   id: 1,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 0,
        //   fieldVer: "2022-02 車險商品部",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 2,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 1,
        //   fieldVer: "2022-02 分公司",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 3,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 2,
        //   fieldVer: "2022-02 個保商品部",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 4,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 3,
        //   fieldVer: "2022-02 企保新種部",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 5,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 4,
        //   fieldVer: "2022-02 企保營一部",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 6,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 0,
        //   fieldVer: "2022-02 個保理賠部",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 7,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 0,
        //   fieldVer: "2022-02 企保營二部",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
        // {
        //   id: 8,
        //   year: "2022",
        //   no: 2,
        //   unit: "台中分公司",
        //   status: 0,
        //   fieldVer: "2022-02 企保營五部",
        //   allocationVer: "2022-02",
        //   editor: "好野人",
        //   versioningAt: "2022-07-01 14:57:49",
        //   updatedAt: "2022-06-18 14:57:49",
        //   createdAt: "2022-06-18 14:33:08",
        // },
      ],
      select_case: [],
      // === Dialogs ===
      addDialog: false,
      deleteAlert: false,
      nowItem: {},
      exportDialog: false,
      filterCondition: "test",
      // 分頁
      pageMeta: {
        options: {
          sortBy: ["createdAt"],
          sortDesc: [true],
        },
        page: 1,
        totalPages: 1,
        itemsPerPage: 10,
      },
      filterData: null,
      // 單位列表
      unitFieldList: [],
      group: null,
      selectedUnitField: [],
      // 配分列表
      allocationList: [],
      allocation: null,
      // 列表中單位領域版本與配分版本顯示
      unitTrans: {},
      allocationTrans: {},
    };
  },
  mounted() {
    this.getList();
    this.getUnitField();
    this.getAllocation();
  },
  methods: {
    openDeleteDialog(item) {
      this.nowItem = item;
      this.deleteAlert = true;
    },
    async getList() {
      try {
        let searchData = this.combineQueries(this.pageMeta, this.filterData);
        let res = await apiGetRiskEvaluation(searchData);
        this.item_list = res.data.content;
        this.pageMeta.totalPages = res.data.totalPages;
        console.log(res);
      } catch (error) {}
    },
    // 分頁搜尋
    pageFilter() {
      let pageParams = arguments[0];
      this.pageMeta.page = pageParams.page;
      this.pageMeta.itemsPerPage = pageParams.itemsPerPage;
      this.getList();
    },
    // 過濾元件
    filterChoose() {
      this.filterData = arguments[0];
      console.log(this.filterData);
      this.getList();
    },
    itemRowBackground(item) {
      let item_idx = this.item_list.findIndex(
        (element) => element.id == item.id
      );
      console.log(item_idx % 2 != 0 ? "style-1" : "style-2");
      return item_idx % 2 != 0 ? "style-1" : "style-2";
    },
    add() {
      this.addDialog = false;
    },
    toPage(item) {
      this.$router.push("/risk-evaluation/setting/" + item.id);
    },
    // 新增風險評估版本
    async addRiskEvaluation() {
      try {
        console.log("here123");
        if (this.allocation == null) {
          this.$set(this.alert, "open", true);
          this.alert.content = "未選擇引用配分版本";
          return;
        }
        if (this.selectedUnitField.length == 0) {
          this.$set(this.alert, "open", true);
          this.alert.content = "未選擇引用單位領域版本";
          return;
        }
        console.log("here234");
        let unitField = [];
        for (let items of this.selectedUnitField) {
          unitField.push(items.id);
        }
        let addData = {
          allocationId: this.allocation,
          unitFieldId: unitField,
        };
        let res = await apiAddRiskEvaluation(addData);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 取得單位領域資料
    async getUnitField() {
      try {
        let searchData = {
          page: 1,
          size: 999,
          status: 1,
        };
        let res = await apiGetUnitField(searchData);
        this.unitFieldList = res.data.content;
        console.log(res);
        this.unitTrans = this.toTrans(this.unitFieldList, "unitField");
      } catch (error) {
        console.log(error);
      }
    },
    // 取得配分設定資料
    async getAllocation() {
      try {
        let searchData = {
          page: 1,
          size: 999,
          status: 1,
        };
        let res = await apiGetAllocationData(searchData);
        console.log(res);
        this.allocationList = res.data.content;
        this.allocationTrans = this.toTrans(this.allocationList, "allocation");
      } catch (error) {
        console.log(error);
      }
    },
    // list轉 obj 翻譯
    toTrans(itemList, type) {
      let objData = {};
      for (let item of itemList) {
        if (type == "unitField") {
          objData[item.id] =
            item.year + "-" + this.versionText(item.no) + "-" + item.group;
        } else if (type == "allocation") {
          objData[item.id] = item.year + "-" + this.versionText(item.no);
        }
      }
      return objData;
    },
    // 刪除風險評估版本
    async deleteRiskEvaluation() {
      try {
        this.deleteAlert = false;
        let res = await apiDeleteRiskEvaluation(this.nowItem.id);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    selectUnit() {
      if (this.group != null) {
        if (
          this.selectedUnitField.indexOf((el) => {
            el.id === this.group.id;
          }) == -1
        ) {
          this.selectedUnitField.push(this.group);
        }
      }
    },
    resetUnit() {
      this.selectedUnitField = [];
      this.group = null;
      this.allocation = null;
    },
  },
};
</script>
<style scoped>
.fontStyle {
  color: grey;
}
.adjust {
  text-align: center;
}
.tableStyle {
  width: 100%;
}
.style-1 {
  background-color: white;
}
.style-2 {
  background-color: #00b1db !important;
  color: white;
}
</style>
