<template>
  <v-dialog v-model="open" width="450">
    <v-card>
      <v-card-title>新增配分版本</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-5">
        <div class="d-flex">
          <span class="text-no-wrap">新增配分版本</span>
          <v-autocomplete
            v-model="versionSelect"
            placeholder="選擇版本"
            outlined
          >
            <template v-slot:prepend>
              新增配分版本號
              <v-chip small>2022-01</v-chip>
            </template>
          </v-autocomplete>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="$emit('add')" small>儲存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    open: { type: Boolean, default: false },
  },
  data() {
    return {
      versionSelect: null,
    };
  },
};
</script>
